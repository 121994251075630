import { useEffect, useRef, useState } from "react"

export const useScroll = () => {
  const prevScrollY = useRef(0)

  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [lastScroll, setLastScroll] = useState(new Date())

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (
        prevScrollY.current < currentScrollY &&
        prevScrollY.current > 0 &&
        isScrollingUp
      ) {
        setIsScrollingUp(false)
      }
      if (
        prevScrollY.current > currentScrollY &&
        prevScrollY.current > 0 &&
        !isScrollingUp
      ) {
        setIsScrollingUp(true)
      }
      setIsScrolling(true)
      setLastScroll(new Date())

      prevScrollY.current = currentScrollY
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [prevScrollY, isScrollingUp])

  setTimeout(() => {
    const currentTime = new Date().getTime()

    if (lastScroll.getTime() < currentTime) {
      setIsScrolling(false)
    }
  }, 100)

  return { isScrolling, isScrollingUp }
}
