import React from "react"
import FeaturedBlog from "../../components/FeaturedBlog/FeaturedBlog"
import { Layout } from "../../layouts"
import BlogList from "../../components/BlogList/BlogList"
import gifImage from "../../assets/images/gif-images/gif-big.svg"
import "./ArticlesList.scss"
import Pager from "../../components/Pager/Pager"
import { graphql } from "gatsby"
import SEO from "../../components/Seo"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"

const BLOGS_PER_PAGE = 6

const ArticlesList = ({ pageContext: { pageData, lang, blogs, companies }, data, location }) => {
  // const { allDzemoApiBlogs } = useMemo(() => localizeDataNodes(data, lang), [
  //   data,
  //   lang,
  // ])

  const featuredBlogs = blogs
    .filter(blog => blog.node[lang].featured)
    .sort((a, b) => (a.node[lang].featured > b.node[lang].featured ? -1 : 1))

  const featuredBlog = featuredBlogs.length > 0 ? featuredBlogs[0].node[lang] : undefined

  return (
    <Layout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
        companies={companies}
      />
      <div className="articles-list-page">
        {featuredBlog && <FeaturedBlog data={featuredBlog} sm />}
        <div className="gif-content">
          <img src={gifImage} alt="gif" width="414" height="81" />
        </div>

        <Pager data={blogs} itemsPerPage={BLOGS_PER_PAGE}>
          {pagedData => (
            <BlogList
              data={pagedData}
              routePrefix={`${lang === "en" ? "" : "/de"}${
                pageData[lang].path
              }`}
            />
          )}
        </Pager>
      </div>
    </Layout>
  )
}

export default ArticlesList

export const query = graphql`
  {
    allDzemoApiBlogs(sort: { fields: en___created_at, order: DESC }) {
      nodes {
        en {
          id
          featured
          name
          route
          created_at(formatString: "MMM d, yyyy")
          image {
            formats {
              articlemore {
                height
                hash
                name
                url
                width
              }
              blog {
                url
                name
                hash
                width
                height
              }
              blog_lg {
                hash
                size
                url
                width
                name
              }
              blog_sm {
                height
                hash
                name
                url
                width
              }
            }
            alternativeText
          }
          abstract
          body
          teaserText
          profile {
            name
            name_de
            linkedin
            position
            shortBio
            email
            image {
              formats {
                team_mini {
                  url
                  width
                  name
                  height
                  hash
                }
                team_small {
                  url
                  width
                  name
                  height
                  hash
                }
              }
              alternativeText
            }
          }
          metadescription
        }
        de {
          id
          featured
          name
          route
          created_at(formatString: "MMM d, yyyy")
          image {
            formats {
              articlemore {
                height
                hash
                name
                url
                width
              }
              blog {
                url
                name
                hash
                width
                height
              }
              blog_lg {
                hash
                size
                url
                width
                name
              }
              blog_sm {
                height
                hash
                name
                url
                width
              }
            }
            alternativeText
          }
          abstract
          body
          teaserText
          profile {
            name
            name_de
            linkedin
            position
            shortBio
            email
            image {
              formats {
                team_mini {
                  url
                  width
                  name
                  height
                  hash
                }
                team_small {
                  url
                  width
                  name
                  height
                  hash
                }
              }
              alternativeText
            }
          }
          metadescription
        }
      }
    }
  }
`
