import React, { useContext } from "react"
import { Link } from "gatsby"
import "./BlogList.scss"
import { LanguageContext } from "../../contexts/languageContext"
import { translateText } from "../../utils/translateText"

//const API_URL = process.env.GATSBY_API_URL

/*
@param image - String, eg. "static/images/example-image.png"
@param title - String, eg. "Example title"
*/

const BlogList = ({ data, routePrefix }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  return (
    <div className="articles-list-container">
      <h2 className="title">
        {`${translateText(
          "All articles",
          currentLanguage.fullTitle,
          googleSheetData
        )}`}
      </h2>
      <div className="articles-list">
        {data.map(blogPost => {
          const { image, name, id, route } = blogPost.node[currentLanguage.label.toLowerCase()]
          const articleMoreImageFormat = image[0].formats.articlemore[0]
          const articleMoreStaticImage = image[0].staticImages.articlemore[1]
          return (
            <Link
              key={id}
              className="blog-content"
              to={`${routePrefix}${route}`}
            >
              <img
                src={`${articleMoreStaticImage.src}`}
                alt={image.alternativeText}
                width={`${articleMoreImageFormat.width}px`}
                height={`${articleMoreImageFormat.height}px`}
              />
              <p className="blog-title">{name}</p>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default BlogList
