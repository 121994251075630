import React, { useEffect, useState } from "react"
import "./Pager.scss"
import { ReactComponent as ArrowLeft } from "../../assets/icons/pager-left-arrow.svg"
import { ReactComponent as ArrowRight } from "../../assets/icons/pager-right-arrow.svg"

const NUMBER_OF_PAGE_ITEMS = 4
const SHOW_MORE_PAGE_NUMBERS_SYMBOL = "..."

const Pager = ({ data, children, itemsPerPage }) => {
  const [pagination, setPagination] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [startPage, setStartPage] = useState(0)
  const numberOfPages = Math.ceil(data.length / itemsPerPage)

  useEffect(() => {
    let tempPagesArray = []
    if (startPage > 0) {
      tempPagesArray.push({
        text: SHOW_MORE_PAGE_NUMBERS_SYMBOL,
        backwards: true,
        show: startPage > 0,
      })
    }
    for (let i = startPage; i < numberOfPages; i++) {
      if (i < NUMBER_OF_PAGE_ITEMS + startPage) {
        tempPagesArray.push({ text: i })
      } else {
        tempPagesArray.push({
          text: SHOW_MORE_PAGE_NUMBERS_SYMBOL,
          upwards: true,
          show: startPage < numberOfPages,
        })
        break
      }
    }
    setPagination(tempPagesArray)
  }, [activePage, numberOfPages, startPage])

  const handleOnPageSelect = (e, pageNumber) => {
    const upwards = e.target.getAttribute("upwards")
    const backwards = e.target.getAttribute("backwards")

    if (pageNumber === SHOW_MORE_PAGE_NUMBERS_SYMBOL) {
      setStartPage(startPage =>
        upwards ? ++startPage : backwards ? --startPage : startPage
      )
    } else {
      setActivePage(pageNumber)
    }
  }

  const handleOnPageBackwards = () => {
    activePage > 1 && setActivePage(currentPage => --currentPage)
  }

  const handleOnPageUpwards = () => {
    activePage < numberOfPages && setActivePage(currentPage => ++currentPage)
  }

  const dataStartIndex = activePage * itemsPerPage - itemsPerPage
  const dataOffset = activePage * itemsPerPage

  return (
    <section className="wrapper">
      {children(data.slice(dataStartIndex, dataOffset))}
      {numberOfPages > 1 && (
        <div className="pager-container">
          <div className="pagination">
            <button onClick={handleOnPageBackwards} disabled={activePage <= 1}>
              <ArrowLeft />
            </button>

            {pagination.map((item, index) => {
              let { text } = item
              const { upwards, backwards, show } = item
              const isItemNumber = !isNaN(Number(text))
              const currentPageNumber = isItemNumber ? ++text : text
              const isCurrentPageActive = currentPageNumber === activePage
              return isItemNumber ? (
                <button
                  key={text}
                  onClick={e => handleOnPageSelect(e, currentPageNumber)}
                  className={`${isCurrentPageActive ? "active" : ""}`}
                >
                  {currentPageNumber}
                </button>
              ) : (
                index < numberOfPages && show && (
                  <button
                    key={`${text}-${upwards ? "up" : backwards ? "back" : ""}`}
                    onClick={e => handleOnPageSelect(e, currentPageNumber)}
                    className={`${isCurrentPageActive ? "active" : ""}`}
                    upwards={upwards && "true"}
                    backwards={backwards && "true"}
                  >
                    {currentPageNumber}
                  </button>
                )
              )
            })}

            <button
              onClick={handleOnPageUpwards}
              disabled={activePage >= numberOfPages}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      )}
    </section>
  )
}

export default Pager
